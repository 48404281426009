// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Gatsby$GlazedWebsite from "../../shared/Gatsby.js";
import * as Heading$GlazedWebsite from "../../Heading.js";
import * as CaseStudyPageTemplate$GlazedWebsite from "./building-blocks/CaseStudyPageTemplate.js";
import * as LogoGlazedSmallSvg from "static/images/logo-glazed-small.svg";

var inPageHeading = Curry._1(Css.style, /* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.maxWidth(Css.rem(64)),
        /* :: */[
          Css.padding2(/* zero */-789508312, Css.rem(1.875)),
          /* :: */[
            Css.margin(/* auto */-1065951377),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var adHocComponent = Curry._1(Css.style, /* :: */[
      Css.textAlign(/* center */98248149),
      /* [] */0
    ]);

var Styles = {
  inPageHeading: inPageHeading,
  adHocComponent: adHocComponent
};

var pageImagesQuery = (Gatsby.graphql`
     query {
       headerImage: file(relativePath: { eq: "case-studies/mercadao-hero.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2400) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       bigImage: file(relativePath: { eq: "case-studies/mercadao-big.png" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage1: file(relativePath: { eq: "case-studies/mercadao-01.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage2: file(relativePath: { eq: "case-studies/mercadao-02.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage3: file(relativePath: { eq: "case-studies/mercadao-03.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       goncaloSoares: file(relativePath: { eq: "case-studies/goncalo-soares-da-costa.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 120) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       nextCaseImage: file(relativePath: { eq: "case-studies/boston-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
     }
  `);

function Mercadao(Props) {
  var queryResult = Gatsby.useStaticQuery(pageImagesQuery);
  var headerImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "headerImage");
  var bigImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "bigImage");
  Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage1");
  var productImage2 = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage2");
  var productImage3 = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage3");
  var quoteAvatarImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "goncaloSoares");
  var nextCaseImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "nextCaseImage");
  var content_hero = {
    image: headerImage,
    title: "Mercadão",
    area: "Marketplace - Ecommerce",
    text: "Mercadão is the first on-demand marketplace in Portugal, introducing 2-hour deliveries from a variety of retailers."
  };
  var content_brief = {
    techs: [
      {
        name: "react",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "docker",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "node",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "aws",
        icon: LogoGlazedSmallSvg.default
      }
    ],
    year: "2020",
    brief: "Provide an overall performance upgrade to their service, integrate a new payment's journey and improve the website response."
  };
  var content_content = [
    /* TextAndImage */Block.__(1, [
        "\n          As one of the top online food and essential goods retailers in Portugal, Mercadão saw a rapid increase in usage as the Pandemic evolved.\n\n          This unforeseen growth brought new scalability challenges that Glazed tackled by revamping the existing infrastructure, implementing auto-scaling strategies, as well as disaster protection and recovery policies.\n\n          Many tasks, such as an in-depth analysis and improvement of the query execution times, had been performed while keeping the platform up and running, allowing the customers to continue their shopping experience uninterrupted.\n",
        bigImage
      ]),
    /* Custom */Block.__(5, [React.createElement(Heading$GlazedWebsite.make, {
              level: /* H3 */2,
              className: inPageHeading,
              children: "Ever since we teamed-up with Mercadão, we\'ve tackled top priorities without undermining the shopper\'s experience."
            })]),
    /* TextAndImage */Block.__(1, [
        "\n        The Click & Collect service, which allows customers to shop online and choose a specific pick-up store, mitigated some of the Pandemic\'s strain on the delivery logistics.\n\n        For that reason, we\'ve created an API for third party usage, empowering the partner brands with Mercadão\'s catalogue of advanced features such as:\n        • Failover mechanisms spread over multiple availability zones\n        • Improved service quality and response time backed by automatic CPU based machine scaling\n        • Integrated the payments journey with SIBS, including the MBway mobile service\n\n",
        productImage3
      ]),
    /* QuoteCard */Block.__(2, [
        "\n      Top-notch development at Glazed allowed Mercadão to leverage on massive demand growth with confidence.\n      Plus: they\'re great people to work with.\n      ",
        {
          name: "Gonçalo Soares da Costa",
          title: "Mercadão CEO",
          avatarImage: quoteAvatarImage
        }
      ]),
    /* TextAndImage */Block.__(1, [
        "\n        In less than a year working with Mercadão, we can confidently state that business processes are now faster, more reliable, and overall infrastructure resource consumption has decreased.\n\n        All those changes have translated into a smoother user experience, an increase of MAU\'s and since January 2020, there has been a daily delivery increase of more than 20x.\n\n        All-in-all, our combined effort created one of the top players in the Portuguese online retail maket.\n\n      ",
        productImage2
      ])
  ];
  var content_nextCase = {
    image: nextCaseImage,
    title: "Boston Hospital",
    area: "HealthTech - IoT",
    link: "/case-studies/boston"
  };
  var content = {
    hero: content_hero,
    brief: content_brief,
    content: content_content,
    nextCase: content_nextCase
  };
  return React.createElement(CaseStudyPageTemplate$GlazedWebsite.make, {
              content: content,
              pageTitle: "Glazed • Mercadão",
              pageDescription: "Mercadão is the first on-demand marketplace in Portugal, introducing 2-hour deliveries from a variety of retailers."
            });
}

var make = Mercadao;

var $$default = Mercadao;

export {
  Styles ,
  pageImagesQuery ,
  make ,
  $$default ,
  $$default as default,
  
}
/* inPageHeading Not a pure module */
